
import VideoWallSelectionItem from "@/components/videoWalls/VideoWallSelectionItem"
import VideoWallGridConfiguration from "@/components/videoWalls/VideoWallGridConfiguration"
import { useVideoWallStore } from "@/stores/videoWall"
import { mapStores } from "pinia"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    VideoWallSelectionItem,
    VideoWallGridConfiguration,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    global: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCameras: [],
      step: 1,
      gridConfiguration: {},
      savingConfiguration: false,
    }
  },
  computed: {
    ...mapStores(useVideoWallStore),
    camerasByProject() {
      const camerasByProject = []
      this.items.forEach(({ value: camera }) => {
        const project = camerasByProject.find(
          (project) => project.name === camera.project.name
        )
        if (project) {
          project.cameras.push(camera)
        } else {
          camerasByProject.push({
            name: camera.project.name,
            id: camera.project.id,
            cameras: [camera],
          })
        }
      })

      return camerasByProject.map((project) => ({
        ...project,
        selectedCameras:
          project.cameras.filter(({ id }) =>
            this.selectedCameras.some((camera) => camera.id === id)
          ).length || 0,
      }))
    },
    dialogState: {
      get() {
        return this.visible
      },
      set(_) {
        this.$emit("close-configuration-dialog")
      },
    },
    allCamerasSelected() {
      return this.selectedCameras.length === this.items.length
    },
    hasCustomPreset() {
      return this.videoWallStore.preset && this.videoWallStore.preset.length
    },
  },
  watch: {
    visible: {
      handler() {
        if (this.hasCustomPreset) {
          this.selectedCameras = this.videoWallStore.preset.map(
            ({ camera }) => camera
          )
        } else {
          this.selectedCameras = this.items.map(({ value }) => value)
        }

        this.gridConfiguration = {}
        this.step = 1
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.hasCustomPreset) {
        this.selectedCameras = this.videoWallStore.preset.map(
          ({ camera }) => camera
        )
      } else {
        this.selectedCameras = this.items.map(({ value }) => value)
      }
    })
  },
  methods: {
    handleCameraItemClick(camera) {
      //add camera if it doesn't exits, remove it if it does
      const cameraIsSelected = this.selectedCameras.some(
        ({ id }) => id === camera.id
      )
      this.selectedCameras = cameraIsSelected
        ? this.selectedCameras.filter(({ id }) => id !== camera.id)
        : this.selectedCameras.concat([camera])
      this.$analytics.saveEvent(
        AnalyticsEvent.videoWallToggleCameraVisibility,
        { visible: !cameraIsSelected }
      )
    },
    async saveConfiguration() {
      this.savingConfiguration = true
      if (this.global) {
        await this.videoWallStore.saveGlobalVideoWallConfiguration(
          this.gridConfiguration
        )
      } else {
        await this.videoWallStore.saveVideoWallConfiguration(
          this.gridConfiguration
        )
      }
      this.$analytics.saveEvent(AnalyticsEvent.videoWallSaveConfig)
      this.savingConfiguration = false
      this.$emit("close-configuration-dialog")
    },
    updateGridConfiguration(gridConfiguration) {
      this.gridConfiguration = gridConfiguration
      this.$analytics.saveEvent(AnalyticsEvent.videoWallPositionCameraOnWall)
    },
    handleProjectCheckboxClick(project) {
      const projectIsSelected =
        project.selectedCameras === project.cameras.length
      const filteredProject = this.selectedCameras.filter(
        ({ project: { id } }) => {
          return id !== project.id
        }
      )
      this.selectedCameras = projectIsSelected
        ? filteredProject
        : filteredProject.concat(project.cameras)
    },
    toggleAllCameras() {
      this.selectedCameras =
        this.selectedCameras.length == this.items.length
          ? []
          : this.items.map(({ value }) => value)
    },
  },
}
