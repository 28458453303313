
import VideoWallItemLiveView from "@/components/videoWalls/VideoWallItemLiveView"
import VideoWallActionButtons from "@/components/videoWalls/VideoWallActionButtons"
import VideoWallConfigurationDialog from "@/components/videoWalls/VideoWallConfigurationDialog"
import VueGridLayout from "vue-grid-layout"
import {
  VideoWallItem,
  VideoWallItemType,
} from "@evercam/shared/types/videoWall"
import { AnalyticsEvent, Media, Camera } from "@evercam/shared/types"
import { PropType } from "vue"
import { useVideoWallStore } from "@/stores/videoWall"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  name: "VideoWallGallery",
  components: {
    VideoWallItemLiveView,
    VideoWallActionButtons,
    VideoWallConfigurationDialog,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    items: {
      type: [] as PropType<VideoWallItem<Camera | Media>>,
      default: () => [],
    },
    preset: {
      type: Array,
      default: () => [],
      required: false,
    },
    global: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      videoWallType: VideoWallItemType,
      showVideoWallActionButton: true,
      isConfigurationDialogVisible: false,
      rowHeight: 1000,
      colWidth: 1000,
      colCount: 8,
      rowCount: 8,
      containerStyle: {},
      fullscreenPreset: [],
      isFullScreen: false,
      generatedPreset: [],
    }
  },
  computed: {
    ...mapStores(useVideoWallStore, useAccountStore),
    projectHasPreset() {
      return this.preset?.length > 0
    },
    currentPreset() {
      if (this.isFullScreen) {
        return this.fullscreenPreset
      } else if (this.projectHasPreset) {
        return this.preset
      }

      return this.generatedPreset
    },
    gridKey() {
      return JSON.stringify(this.preset)
    },
  },
  watch: {
    isConfigurationDialogVisible(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.videoWallToggleConfigDialog, {
        visible,
      })
    },
  },
  mounted() {
    this.$registerInactivityListener({
      callback: this.onIdle,
      duration: 3,
      reminders: [110],
    })
    if (!this.projectHasPreset) {
      this.generatedPreset = this.generatePreset()
    }
  },
  beforeDestroy() {
    this.videoWallStore.preset = []
  },
  methods: {
    getNumberOfRowsForItemWidth(itemWidth) {
      const container = this.$refs.container.getBoundingClientRect()
      const itemsPerRow = Math.floor(container.width / itemWidth)

      return Math.floor(this.items.length / itemsPerRow)
    },
    setTileSize() {
      const container = this.$refs.container.getBoundingClientRect()
      const gridWidth = container.width
      const gridHeight = window.innerHeight - container.top

      this.rowHeight = Math.floor(gridHeight / 8)
      this.colWidth = Math.floor(gridWidth / 8)
      this.containerStyle = {
        height: `${gridHeight}px`,
      }
    },
    onIdle() {
      this.showVideoWallActionButton = false
    },
    mouseMove() {
      this.showVideoWallActionButton = true
    },
    openSettings() {
      this.isConfigurationDialogVisible = true
    },
    closeSettings() {
      this.isConfigurationDialogVisible = false
    },
    toggleItem(i) {
      if (this.isFullScreen) {
        this.isFullScreen = false
        this.fullscreenPreset = []
      } else {
        this.fullscreenPreset = this.currentPreset.reduce((acc, item) => {
          if (item.i === i) {
            return [
              {
                ...item,
                x: 0,
                y: 0,
                w: 8,
                h: 8,
              },
            ]
          }

          return acc
        }, [])

        this.isFullScreen = true
      }
      this.$analytics.saveEvent(
        AnalyticsEvent.videoWallToggleCameraFullscreen,
        { active: this.isFullScreen }
      )
    },
    generatePreset() {
      const gridSize = 8
      const totalGridCells = gridSize * gridSize
      const numberOfItems = this.items.length
      const areaPerItem = Math.floor(totalGridCells / numberOfItems)
      const itemSideLength = Math.floor(Math.sqrt(areaPerItem))

      let layout = []
      let currentX = 0
      let currentY = 0

      this.items.forEach((item, index) => {
        // Update here for future aspect ratio support
        let itemWidth = itemSideLength
        let itemHeight = itemSideLength

        if (currentX + itemWidth > gridSize) {
          currentX = 0
          currentY += itemHeight
        }

        if (currentY + itemHeight > gridSize) {
          itemHeight = gridSize - currentY
        }

        layout.push({
          i: index.toString(),
          x: currentX,
          y: currentY,
          w: itemWidth,
          h: itemHeight,
          camera: item.value,
        })

        currentX += itemWidth
      })

      return layout
    },
  },
}
