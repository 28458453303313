
import SnapshotPoller from "@evercam/shared/components/SnapshotPoller"
import Vue, { PropType } from "vue"
import { Camera, CameraStatus } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
const second = 1000

export default Vue.extend({
  name: "VideoWallItemLiveView",
  components: {
    SnapshotPoller,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    refreshRate: {
      type: [Number, undefined],
      default: 50 * second, // 1 snapshot every 50 seconds
    },
    token: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: true,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socket: null,
      retryCount: 0,
      idle: false,
      aspectClass: "landscape",
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
    cameraUrl() {
      return `/v2/projects/${this.camera.project?.id}/${this.camera.id}${
        [CameraStatus.DECOMMISSIONED, CameraStatus.ON_HOLD].includes(
          this.camera.status
        )
          ? "/recordings"
          : ""
      }`
    },
  },
  mounted() {
    this.$registerInactivityListener({
      callback: this.onIdle,
      duration: 2,
      reminders: [110],
    })
  },
  methods: {
    onIdle() {
      this.idle = true
    },
    mouseMove() {
      this.idle = false
    },
    onResize() {
      const element = this.$refs.cellContainer
      const aspectRatio =
        Math.round(element?.clientWidth / element?.clientHeight) || 0
      this.aspectClass = aspectRatio >= 1 ? "landscape" : "portrait"
    },
  },
})
