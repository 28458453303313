
import VueGridLayout from "vue-grid-layout"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cameraGridItems: [],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  watch: {
    cameraGridItems: {
      handler(value) {
        this.$emit("grid-configuration-updated", value)
      },
      deep: true,
    },
  },
  mounted() {
    const tileSize = this.items.length > 16 ? 1 : 2
    const step = this.items.length > 16 ? 8 : 4
    this.cameraGridItems = this.items.map((item, index) => {
      const itemConfiguration = {
        x: (index % step) * tileSize, // 0,2,4,6,0,2,4,6...
        y: Math.floor(index / step) * tileSize, /// 0,0,0,0,2,2,2,2...
        w: tileSize,
        h: tileSize,
        i: index.toString(),
        camera: { ...item },
      }

      return itemConfiguration
    })
  },
}
