
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  name: "VideoWallSelectionItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useAccountStore),
  },
}
